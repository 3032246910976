// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brandy-js": () => import("./../src/pages/brandy.js" /* webpackChunkName: "component---src-pages-brandy-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gin-js": () => import("./../src/pages/gin.js" /* webpackChunkName: "component---src-pages-gin-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-rum-js": () => import("./../src/pages/rum.js" /* webpackChunkName: "component---src-pages-rum-js" */),
  "component---src-pages-tequila-js": () => import("./../src/pages/tequila.js" /* webpackChunkName: "component---src-pages-tequila-js" */),
  "component---src-pages-tos-js": () => import("./../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-vodka-js": () => import("./../src/pages/vodka.js" /* webpackChunkName: "component---src-pages-vodka-js" */),
  "component---src-pages-whiskey-js": () => import("./../src/pages/whiskey.js" /* webpackChunkName: "component---src-pages-whiskey-js" */),
  "component---src-templates-drink-js": () => import("./../src/templates/drink.js" /* webpackChunkName: "component---src-templates-drink-js" */),
  "component---src-templates-paginated-list-js": () => import("./../src/templates/paginatedList.js" /* webpackChunkName: "component---src-templates-paginated-list-js" */)
}

